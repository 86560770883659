import FeatureCompanies from "./FeatureCompanies";
import heroImage from "../assets/image.png";

const Hero = () => {
    const handleBookCall = () => {
        // You would replace this with your actual booking link or modal
        window.open('https://calendly.com/bonaogeto/book-a-call', '_blank');
    };
    

    return (
        <div className="hero">
            <section className="bg-white dark:bg-gray-900">
                <div className="grid max-w-screen-xl px-4 mx-auto h-screen lg:h-[calc(90vh)] lg:gap-8 xl:gap-0 lg:grid-cols-12 lg:items-center">
                    <div className="mr-auto place-self-center lg:col-span-7">
                        <h1 className="max-w-2xl mb-4 mt-20 text-3xl font-extrabold leading-snug tracking-tight sm:mt-16 md:text-4xl xl:text-5xl dark:text-white">
                           <br/> Where Swiss Quality <br /> Meets Tech Education
                        </h1>
                        <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-base lg:text-lg dark:text-gray-400">
                            Whether you're a recent graduate, a university student, or a professional ready to upskill or switch to tech, we provide world-class training designed to fit your journey. Dive into Web Development, Software Engineering, or Data Science, and build career-defining projects that set you apart. At Swiss Tech Academy, we don’t just teach you to code—we empower you to lead, innovate, and thrive in the tech world. Ready to take the leap? Your future starts here.
                        </p>
                        <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                            <button 
                                onClick={handleBookCall}
                                className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300 transform hover:scale-105 shadow-lg"
                            >
                                Book Free Consultation
                            </button>
                            {/* <a
                                href="/get-started"
                                className="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:w-auto focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                            >
                                Get Started
                            </a> */}
                        </div>
                    </div>
                    <div className="relative mt-10 lg:mt-0 lg:col-span-5 h-64 sm:h-80 md:h-96 lg:h-full">
                        <img
                            src={heroImage}
                            alt="Hero section illustration"
                            className="w-full h-full object-cover rounded-lg shadow-lg"
                        />
                    </div>
                </div>
            </section>
            <FeatureCompanies />
        </div>
    );
};

export default Hero;
