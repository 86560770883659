import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Analytics } from "@vercel/analytics/react";
import { Toaster } from 'react-hot-toast';
import { initializeGA } from './utils/analytics';
import { useAnalytics } from './hooks/useAnalytics';

// Context Providers
import { AuthProvider, useAuth } from './context/AuthContext';
import { DashboardProvider } from './context/DashboardContext';

// Layout Components
import Header from "./components/Header";
import Footer from './components/Footer';

// Public Pages
import Hero from "./components/Hero";
import GuidanceCallSection from './components/GuidanceCallSection';
import WhyChooseUs from './components/WhyChooseUs';
import Stats from './components/Stats';
import Testimonials from './components/Testimonials';
import FAQs from './components/FAQs';
import JoinUsToday from './components/JoinUsToday';
// import ComingSoon from './components/ComingSoon';
import Programs from './components/Programs';
import ProgramDetails from './components/ProgramDetails';
import StudentsPage from './components/StudentsPage';
import CountriesPage from './components/CountriesPage';
import ContinuousCardCarousel from './components/ContinuousCardCarousel';

// Auth Components
import Login from './components/auth/Login';
import Signup from './components/auth/Signup';
import VerifiedRoute from './components/auth/VerifiedRoute';
import ProtectedRoute from './components/auth/ProtectedRoute';
import VerifyEmail from './components/auth/VerifyEmail';

// Protected Components
import ProfileCreation from './components/auth/ProfileCreation';
import CourseSelection from './components/auth/CourseSelection';
import PaymentPage from './components/auth/PaymentPage';
import Dashboard from './components/auth/Dashboard';
import AdminRoutes from './components/pages/admin-dashboard/AdminRoutes';


// Analytics wrapper component
const AnalyticsWrapper = ({ children }) => {
  useAnalytics(); // Now this hook is used within Router context
  return children;
};

// Landing page sections combined into a single component
const LandingPageSections = () => (
  <>
    <Hero />
    <ContinuousCardCarousel />
    <Programs />
    <WhyChooseUs />
    <GuidanceCallSection />
    <Stats />
    <Testimonials />
    <FAQs />
    <JoinUsToday />
  </>
);

function App() {
  useEffect(() => {
    initializeGA();
  }, []);

  return (
    <Router>
      <AnalyticsWrapper>
        <Toaster position="top-right" />
        <AuthProvider>
          <div className="flex flex-col min-h-screen">
            <Header />
            
            <main className="flex-grow">
              <Routes>
                {/* Public Routes */}
                {/* <Route path="/" element={<ComingSoon />} /> */}
                <Route path="/" element={<LandingPageSections />} />
                <Route path="/programs" element={<Programs />} />
                <Route path="/programs/:programTitle" element={<ProgramDetails />} />
                <Route path="/students" element={<StudentsPage />} />
                <Route path="/countries" element={<CountriesPage />} />

                {/* Authentication Routes */}
                <Route path="/login" element={
                  <PublicOnlyRoute>
                    <Login />
                  </PublicOnlyRoute>
                } />
                <Route path="/signup" element={
                  <PublicOnlyRoute>
                    <Signup />
                  </PublicOnlyRoute>
                } />

                {/* Protected Routes with Progressive Access */}
                <Route path="/verify-email" element={<VerifyEmail />} />

                {/* Protected Routes */}
                <Route path="/create-profile" element={
                  <VerifiedRoute>
                    <ProfileCreation />
                  </VerifiedRoute>
                } />

                <Route path="/course-selection" element={
                  <ProtectedRoute 
                    requiredStatus="profile-created"
                    fallbackPath="/create-profile"
                  >
                    <CourseSelection />
                  </ProtectedRoute>
                } />

                <Route path="/payment" element={
                  <ProtectedRoute 
                    requiredStatus="courses-selected"
                    fallbackPath="/course-selection"
                  >
                    <PaymentPage />
                  </ProtectedRoute>
                } />

                <Route path="/dashboard/*" element={
                  <ProtectedRoute 
                    requiredStatus="profile-created"
                    fallbackPath="/create-profile"
                  >
                    <DashboardProvider>
                      <Dashboard />
                    </DashboardProvider>
                  </ProtectedRoute>
                } />

                <Route path="/admin/*" element={
                  <ProtectedRoute
                    requiredRoles={["admin","super_admin"]}
                    fallbackPath="/dashboard"
                  >
                    <AdminRoutes />
                  </ProtectedRoute>
                } />

                {/* Fallback route */}
                <Route path="*" element={<Navigate to="/home" replace />} />
              </Routes>
            </main>

            <Footer />
            <Analytics />
          </div>
          </AuthProvider>
      </AnalyticsWrapper>
    </Router>
  );
}

// Component to prevent authenticated users from accessing public routes
const PublicOnlyRoute = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      // If user is already authenticated, redirect to appropriate page
      if (!user.profileComplete) {
        navigate('/create-profile');
      } else if (!user.coursesSelected) {
        navigate('/course-selection');
      } else if (!user.paymentCompleted) {
        navigate('/payment');
      } else {
        navigate('/dashboard');
      }
    }
  }, [user, navigate]);

  // Only render children if user is not authenticated
  return !user ? children : null;
};

export default App;