import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Upload, Plus, Minus, Clock, DollarSign, 
  Save, Eye, CheckCircle, Image as ImageIcon 
} from 'lucide-react';
import api from '../../../config/axios';
import { apiService, endpoints } from '../../../config/axios';
import { toast } from 'react-hot-toast';

const CreateCourse = () => {
  const [courseData, setCourseData] = useState({
    title: '',
    description: '',
    shortDescription: '',
    category: '',
    level: 'beginner',
    price: '',
    thumbnail: null,
    duration: '',
    prerequisites: [''],
    learningObjectives: [''],
    lmsUrl: '',
    lmsCourseId: '',
    enrollmentType: 'open',
    metadata: {
      seoTitle: '',
      seoDescription: '',
      tags: [],
      trackingId: ''
    },
    status: 'draft'
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  // Handle dynamic field arrays (prerequisites and learning objectives)
  const handleArrayFieldChange = (field, index, value) => {
    setCourseData(prev => {
      const newArray = [...prev[field]];
      newArray[index] = value;
      return { ...prev, [field]: newArray };
    });
  };

  const handleAddArrayField = (field) => {
    setCourseData(prev => ({
      ...prev,
      [field]: [...prev[field], '']
    }));
  };

  const handleRemoveArrayField = (field, index) => {
    setCourseData(prev => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index)
    }));
  };

  // Handle metadata changes
  const handleMetadataChange = (key, value) => {
    setCourseData(prev => ({
      ...prev,
      metadata: {
        ...prev.metadata,
        [key]: value
      }
    }));
  };

  // Handle tags
  const handleTagsChange = (e) => {
    const tags = e.target.value.split(',').map(tag => tag.trim());
    handleMetadataChange('tags', tags);
  };

  const validateCourse = () => {
    const errors = {};
    if (!courseData.title) errors.title = 'Title is required';
    if (!courseData.description) errors.description = 'Description is required';
    if (!courseData.shortDescription) errors.shortDescription = 'Short description is required';
    if (!courseData.price) errors.price = 'Price is required';
    if (!courseData.category) errors.category = 'Category is required';
    if (!courseData.thumbnail) errors.thumbnail = 'Thumbnail is required';
    if (!courseData.lmsUrl) errors.lmsUrl = 'LMS URL is required';
    
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleThumbnailUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
    if (!validTypes.includes(file.type)) {
      setValidationErrors(prev => ({
        ...prev,
        thumbnail: 'Please upload a valid image file (JPEG, PNG, or WebP)'
      }));
      return;
    }

    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = 800;
          const scale = MAX_WIDTH / img.width;
          canvas.width = MAX_WIDTH;
          canvas.height = img.height * scale;
          
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          
          const optimizedDataUrl = canvas.toDataURL('image/webp', 0.8);
          setCourseData(prev => ({
            ...prev,
            thumbnail: optimizedDataUrl
          }));
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error processing image:', error);
      setValidationErrors(prev => ({
        ...prev,
        thumbnail: 'Error processing image. Please try again.'
      }));
    }
  };

  const handlePublish = async () => {
    if (!validateCourse()) return;

    setIsSubmitting(true);
    try {
      const coursePayload = {
        ...courseData,
        status: 'published',
        publishedAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      // First upload the thumbnail if it exists
      let thumbnailUrl = null;
      if (courseData.thumbnail && courseData.thumbnail.startsWith('data:image')) {
        // Convert base64 to file
        const response = await fetch(courseData.thumbnail);
        const blob = await response.blob();
        const file = new File([blob], 'thumbnail.webp', { type: 'image/webp' });

        // Create form data for file upload
        const formData = new FormData();
        formData.append('thumbnail', file);

        // Upload thumbnail first
        const uploadResponse = await apiService.post('/api/uploads/thumbnail', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        thumbnailUrl = uploadResponse.thumbnailUrl;
      }

      // Update payload with thumbnail URL if uploaded
      const finalPayload = {
        ...coursePayload,
        thumbnail: thumbnailUrl || courseData.thumbnail,
      };

      // Create course using the API service
      const response = await apiService.post(endpoints.createCourse, finalPayload);

      // Show success message and navigate
      toast.success('Course published successfully!');
      navigate('/admin/courses', { 
        state: { message: 'Course published successfully!' }
      });
    } catch (error) {
      // Handle specific error cases
      let errorMessage = 'Failed to create course';

      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 400:
            errorMessage = 'Invalid course data. Please check all required fields.';
            break;
          case 401:
            errorMessage = 'You are not authorized to create courses.';
            break;
          case 413:
            errorMessage = 'Thumbnail image is too large. Please use a smaller image.';
            break;
          case 415:
            errorMessage = 'Invalid image format. Please use JPEG, PNG, or WebP.';
            break;
          case 500:
            errorMessage = 'Server error. Please try again later.';
            break;
          default:
            errorMessage = error.response.data?.message || errorMessage;
        }
      }

      // Update validation errors
      setValidationErrors(prev => ({
        ...prev,
        submit: errorMessage
      }));

      // Show error toast
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-5xl mx-auto mt-9 pt-6 p-6">
      <div className="bg-white rounded-lg shadow-sm">
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-2">Create New Course</h1>
          <p className="text-gray-600 mb-6">Fill in the course details below</p>

          {/* Basic Information */}
          <section className="mb-8">
            <h2 className="text-lg font-semibold mb-4">Basic Information</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">Course Title *</label>
                <input
                  type="text"
                  value={courseData.title}
                  onChange={(e) => setCourseData(prev => ({
                    ...prev,
                    title: e.target.value
                  }))}
                  className="w-full rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="e.g., Advanced Web Development"
                />
                {validationErrors.title && (
                  <p className="text-red-500 text-sm mt-1">{validationErrors.title}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Short Description *</label>
                <input
                  type="text"
                  value={courseData.shortDescription}
                  onChange={(e) => setCourseData(prev => ({
                    ...prev,
                    shortDescription: e.target.value
                  }))}
                  className="w-full rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Brief overview of the course"
                />
                {validationErrors.shortDescription && (
                  <p className="text-red-500 text-sm mt-1">{validationErrors.shortDescription}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Detailed Description *</label>
                <textarea
                  value={courseData.description}
                  onChange={(e) => setCourseData(prev => ({
                    ...prev,
                    description: e.target.value
                  }))}
                  rows={4}
                  className="w-full rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Detailed description of what students will learn"
                />
                {validationErrors.description && (
                  <p className="text-red-500 text-sm mt-1">{validationErrors.description}</p>
                )}
              </div>
            </div>
          </section>

          {/* Course Details */}
          <section className="mb-8">
            <h2 className="text-lg font-semibold mb-4">Course Details</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">Category *</label>
                <select
                  value={courseData.category}
                  onChange={(e) => setCourseData(prev => ({
                    ...prev,
                    category: e.target.value
                  }))}
                  className="w-full rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select Category</option>
                  <option value="web-development">Web Development</option>
                  <option value="mobile-development">Mobile Development</option>
                  <option value="data-science">Data Science</option>
                  <option value="design">Design</option>
                  <option value="business">Business</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Level</label>
                <select
                  value={courseData.level}
                  onChange={(e) => setCourseData(prev => ({
                    ...prev,
                    level: e.target.value
                  }))}
                  className="w-full rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="beginner">Beginner</option>
                  <option value="intermediate">Intermediate</option>
                  <option value="advanced">Advanced</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Price (USD) *</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <DollarSign className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    value={courseData.price}
                    onChange={(e) => setCourseData(prev => ({
                      ...prev,
                      price: e.target.value
                    }))}
                    className="pl-10 w-full rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="99.99"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Duration</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Clock className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    value={courseData.duration}
                    onChange={(e) => setCourseData(prev => ({
                      ...prev,
                      duration: e.target.value
                    }))}
                    className="pl-10 w-full rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="e.g., 6 weeks"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Enrollment Type</label>
                <select
                  value={courseData.enrollmentType}
                  onChange={(e) => setCourseData(prev => ({
                    ...prev,
                    enrollmentType: e.target.value
                  }))}
                  className="w-full rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="open">Open Enrollment</option>
                  <option value="scheduled">Scheduled</option>
                  <option value="closed">Closed</option>
                </select>
              </div>
            </div>
          </section>

          {/* Prerequisites & Learning Objectives */}
          <section className="mb-8">
            <h2 className="text-lg font-semibold mb-4">Prerequisites & Learning Objectives</h2>
            
            <div className="space-y-6">
              {/* Prerequisites */}
              <div>
                <label className="block text-sm font-medium mb-2">Prerequisites</label>
                {courseData.prerequisites.map((prerequisite, index) => (
                  <div key={index} className="flex gap-2 mb-2">
                    <input
                      type="text"
                      value={prerequisite}
                      onChange={(e) => handleArrayFieldChange('prerequisites', index, e.target.value)}
                      className="flex-1 rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="e.g., Basic JavaScript knowledge"
                    />
                    <button
                      onClick={() => handleRemoveArrayField('prerequisites', index)}
                      className="p-2 text-red-600 hover:text-red-700"
                    >
                      <Minus className="w-5 h-5" />
                    </button>
                  </div>
                ))}
                <button
                  onClick={() => handleAddArrayField('prerequisites')}
                  className="flex items-center gap-1 text-sm text-blue-600 hover:text-blue-700">
                  <Plus className="w-4 h-4" />
                  Add Learning Objective
                </button>
              </div>
            </div>
          </section>

          {/* LMS Integration */}
          <section className="mb-8">
            <h2 className="text-lg font-semibold mb-4">LMS Integration</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">
                  LMS Course URL *
                </label>
                <input
                  type="url"
                  value={courseData.lmsUrl}
                  onChange={(e) => setCourseData(prev => ({
                    ...prev,
                    lmsUrl: e.target.value
                  }))}
                  className="w-full rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="https://yourlms.com/course/..."
                />
                {validationErrors.lmsUrl && (
                  <p className="text-red-500 text-sm mt-1">{validationErrors.lmsUrl}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">
                  LMS Course ID
                </label>
                <input
                  type="text"
                  value={courseData.lmsCourseId}
                  onChange={(e) => setCourseData(prev => ({
                    ...prev,
                    lmsCourseId: e.target.value
                  }))}
                  className="w-full rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="course_123"
                />
              </div>
            </div>
          </section>

          {/* SEO and Metadata */}
          <section className="mb-8">
            <h2 className="text-lg font-semibold mb-4">SEO & Metadata</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">
                  SEO Title
                </label>
                <input
                  type="text"
                  value={courseData.metadata.seoTitle}
                  onChange={(e) => handleMetadataChange('seoTitle', e.target.value)}
                  className="w-full rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="SEO optimized title"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">
                  SEO Description
                </label>
                <textarea
                  value={courseData.metadata.seoDescription}
                  onChange={(e) => handleMetadataChange('seoDescription', e.target.value)}
                  rows={3}
                  className="w-full rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="SEO optimized description"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">
                  Tags (comma-separated)
                </label>
                <input
                  type="text"
                  value={courseData.metadata.tags.join(', ')}
                  onChange={handleTagsChange}
                  className="w-full rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="web development, javascript, react"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">
                  Tracking ID
                </label>
                <input
                  type="text"
                  value={courseData.metadata.trackingId}
                  onChange={(e) => handleMetadataChange('trackingId', e.target.value)}
                  className="w-full rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="UA-XXXXX-Y"
                />
              </div>
            </div>
          </section>

          {/* Course Thumbnail */}
          <section className="mb-8">
            <h2 className="text-lg font-semibold mb-4">Course Thumbnail</h2>
            <div className="border-2 border-dashed border-gray-300 rounded-lg p-6">
              {courseData.thumbnail ? (
                <div className="relative">
                  <img
                    src={courseData.thumbnail}
                    alt="Course thumbnail"
                    className="max-w-md rounded-lg mx-auto"
                  />
                  <button
                    onClick={() => setCourseData(prev => ({ ...prev, thumbnail: null }))}
                    className="absolute top-2 right-2 p-1 bg-red-600 text-white rounded-full hover:bg-red-700"
                  >
                    <Minus className="w-4 h-4" />
                  </button>
                </div>
              ) : (
                <div className="text-center">
                  <ImageIcon className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="mt-4">
                    <label className="cursor-pointer">
                      <span className="text-blue-600 hover:text-blue-500">Upload a file</span>
                      <input
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={handleThumbnailUpload}
                      />
                    </label>
                    <p className="text-sm text-gray-500 mt-1">PNG, JPG, GIF up to 10MB</p>
                  </div>
                </div>
              )}
              {validationErrors.thumbnail && (
                <p className="text-red-500 text-sm mt-2">{validationErrors.thumbnail}</p>
              )}
            </div>
          </section>

          {/* Action Buttons */}
          <div className="flex justify-end gap-4 pt-6 border-t">
            <button
              onClick={() => navigate('/admin/courses')}
              className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={handlePublish}
              disabled={isSubmitting}
              className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
            >
              {isSubmitting ? (
                <span>Publishing...</span>
              ) : (
                <>
                  <CheckCircle className="w-5 h-5" />
                  Publish Course
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCourse;